.quote {
    @extend .background--lighter;
    color: $font-color-light;
    border-radius: 4px;
    position: relative;
    text-align: center;
    @extend .space__bottom__big;
    @extend .space__top__big;
    &:before {
        content: "";
        right: -20px;
        top: 20%;
        position: absolute;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid rgba($light, .85);
    }

    &__wrapper {
        @include x-rem(padding, 15px);
    }
    &__image {
        @extend .space__bottom__small;
    }
    &__title {
        @extend .space__bottom__medium;
        p {
            font-size: 12px;
            letter-spacing: 2.16px;
            line-height: 18px;
        }
        strong {
          font-weight: 800;
        }
    }
    &__text {
        @extend .space__bottom__small;
        p {
            @include font-size($p-font-sizes-small);
        }

    }
}

@include mq($until: tablet) {
    .quote {
        &:before {
            display: none;
        }
    }
}
