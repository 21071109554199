.dropdown__toggle {
  cursor: default;
}

.dropdown {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  margin-top: 3px;

  .dropdown__item {
    display: block;
    color: black;
    padding: 0.3571428571rem 0.7142857143rem;
    font-size: 1em;
    text-decoration: none;
    text-align: left;

    &:hover {
      background-color: #ddd;
    }
  }

  @include mq($until: desktop) {
    position: static;
    font-size: 14px; /* Adjust this value as per your requirements */
  }
}

.menu__item {
  &:hover {
    .dropdown {
      display: block;
    }
  }
}

@include mq($until: desktop) {
  .navigation {
    position: relative;
    height: 100%;
    width: 100%;
    @include x-rem(margin-top, 15px);

    .menu {
      min-width: 150px;
      display: block;
      position: absolute;
      right: 0;
      pointer-events: none;
      opacity: 0;
      transition: 0.6s ease;

      &__item {
        text-align: left;
      }

      &__link {
        color: $second-color;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: -1000%;
        left: -1000%;
        z-index: -1;
        bottom: 0;
        background: $light;
      }
    }

    .dropdown {
        display: block;
        position: unset;
    }

    .dropdown .dropdown__item {
        color: $second-color;
        background-color: $light;
        padding: 0;
        padding-left: 10px;
    }
  }
}

@include mq($from: desktop) {
  .navigation {
    display: flex;
    max-width: 65%;

    .menu {
      display: inherit;
      height: 100%;
      width: 100%;

      &__item {
        text-align: left;

        &--is-active {
          .menu__link {
            background-color: $second-color;
            color: $light;

            .color__light & {
              background-color: $main-color;
              color: $light;
            }

            .color__light-dark & {
              background-color: $second-color;
              color: $light;
            }

            .color__red & {
              background-color: $second-color;
              color: $light;
            }
          }

          &:hover {
            .link.menu__link {
            }
          }
        }

        &:hover {
          .menu__link {
            box-sizing: border-box;
            border: 2px solid $second-color;
            @include x-rem(padding, 5px 10px);

            .color__light & {
              border-color: $main-color;
            }

            .color__light-dark & {
              border-color: $second-color;
            }

            .color__red & {
              border-color: $light;
            }
          }
        }
      }

      &__link {
        @include x-rem(padding, 5px 10px);
        @include font-size($nav-font-sizes);
        border: 2px solid transparent;
      }

      .background--white.color__light & {
        .menu__item {
          &--is-active {
            .link.menu__link {
              color: $light;
            }
          }
        }
        .link.menu__link {
          color: $main-color;
        }
      }

      .background--white.color__light-dark & {
        .menu__item {
          &--is-active {
            .link.menu__link {
              color: $light;
            }
          }
        }
        .link.menu__link {
          color: $second-color;
        }
      }

      .color__red & {
        .menu__item {
          &--is-active {
            .link.menu__link {
              color: $light;
            }
          }
        }
        .link.menu__link {
          color: $light;
        }
      }
    }
  }
}
