.container {
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &__xsmall {
        max-width: 720px;
    }
    &__small {
        max-width: 960px;
    }
    &__medium {
        max-width: 1200px;
    }
    &__big {
        max-width: 1440px;
    }
    &__full {
        max-width: 100%;
    }
}