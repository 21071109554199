form.login--form {
  width: 92%;
  margin: 30px auto;
  padding: 20px;
  @media screen and (min-width: 767px) {
    padding: 40px;
    width: 400px;
    margin: 50px auto;
  }
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  .alert {
    text-align: center;
    color: $main-color;
    padding: 0 0 1rem;
  }
  label {
    display: block;
    color: $second-color;
    margin-bottom: 0.125rem;
  }
  input[type="text"],
  input[type="password"] {
    border: 0;
    padding: 8px;
    font-size: 1rem;
    font-family: $body-font;
    color: $second-color;
    width: 100%;
    margin-bottom: 1rem;
  }
  input[type="submit"] {
    display: block;
    color: #fff;
    background-color: $second-color;
    font-family: $body-font;
    font-size: 1.25rem;
    text-align: center;
    width: 67%;
    margin: 0.25rem auto;
    border: 0;
    padding: 10px 0;
    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      color: $second-color;
      background-color: #fff;
    }
  }
}

.protected {
  .content__headline {
    @include font-size($h2-font-sizes);
    @extend .space__bottom__medium;
  }
  .fileset {
    .description {
      // p {
      //   font-size: 1.125rem;
      //   line-height: 1.5;
      // }
    }
    .files a {
      display: inline-block;
      @extend .link--bordered;
      text-decoration: none;
      transition: 200ms ease;
      color: $main-color;
      border-color: $main-color;
      font-weight: bold;
      @extend .space__bottom__small;
      &:after {
        content: ">";
        @include x-rem(margin-left, 10px);
      }
      // .colorset1 & {
      //   color: $light;
      // }
      // .colorset__light & {
      //   color: $font-color;
      // }
      // .colorset__light-red & {
      //   color: $main-color;
      // }
      &:hover {
        color: $font-color;
        border-color: $font-color;
      }
      // .colorset__light & {
      //   border-color: $font-color;
      //   &:hover {
      //     border-color: $light;
      //   }
      // }
      // .colorset__light-red & {
      //   border-color: $main-color;
      //   &:hover {
      //     border-color: $light;
      //   }
      // }
    }
  }
  .subheadline {
    @extend .space__top__medium;
  }
}
