.news_list {
  &__item {
    background: $light;
    @include x-rem(padding, 15px 30px);
    display: flex;
  }
  &__meta {
    text-align: right;
    p {
      @include font-size($p-font-sizes-small)
    }
  }
  &__file {
    text-align: right;
    flex: 0 0 auto;
    @include x-rem(margin-left, 20px);
  }
}

@include mq($until: tablet) {
  .news_list {

    &__item {
      width: 100%;
      flex-wrap: wrap;
    }
    &__meta {

      text-align: left;
      width: 100%;
      @include x-rem(margin-top, 10px);
    }
    &__file {
      @include x-rem(margin-top, 10px);
      text-align: left;
      margin-left: 0;
    }
  }
}