.logo {
    max-width: 250px;

    &__link {
        color: $font-color;
        &--inverted, .color__light &, .color__light-dark & {
            color: $font-color-inverted;

        }
        .color__light.background--white & {
            color: $main-color;
        }
        .color__light-dark.background--white & {
            color: $second-color;
        }
        .color__red & {
            color: $light;
        }

    }
    &__title {
        font-size: 36px;
        font-weight: bold;
        &:after {
            content: '®';
            font-size: 11px;
            position: relative;
            bottom: 18px;
            margin-left: 1px;
        }
    }
    &:before {
        content: url('/assets/images/logo_blue.png');
        display: block;
        height: 42px;
        width: 42px;
        @include x-rem(margin-right, 10px);
        .color__light-dark & {
            content: url('/assets/images/logo_white.png');
        }
        .color__light & {
            content: url('/assets/images/logo_white.png');
        }
        .background--white & {
            content: url('/assets/images/logo_blue.png');
        }
        .background--white.color__light & {
            content: url('/assets/images/logo_red.png');
        }
        .color__red & {
            content: url('/assets/images/logo_white.png');
        }
    }

}
