.section {
    @include x-rem(padding, 10px);
    &__headline {
        //.colorset_reg & {
        //    position: relative;
        //    &:after {
        //        content: "";
        //        position: absolute;
        //        left: 50%;
        //        right: 0;
        //        bottom: 0;
        //        height: 5px;
        //        width: 100%;
        //        background: $light;
        //    }
        //}

    }
    &.colorset__light {
        @extend .background--light;
        color: $font-color;
    }
    &.colorset__light-red {
        @extend .background--light;
        color: $main-color;
    }

}