.link {
    text-decoration: none;
    color: $font-color;
    &--highlight {
        color: $main-color;
        &:hover {
            color: $font-color-inverted;
        }
    }
    &--inverted, .color__light &,.color__light-dark & {
        color: $font-color-inverted;
        &:hover {
            //color: $main-color;
        }
    }
    &--white {
        color: $light;
    }
    &--bordered {
        padding-bottom: 3px;
        border-bottom: 5px solid #fff;
    }

}