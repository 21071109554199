.footer {
    color: $light;
    @include x-rem(padding-left,10px);
    @include x-rem(padding-left,10px);

}

.section__footer {
    color: $light;

}

@include mq($until: tablet) {
    .section__footer {

        .flex__item {
            flex: 1 1 100%;
        }
        &__socials {
            .icon-link {
                margin: 0;
                text-align: center;
            }
        }
    }
}

@include mq($from: tablet) {
    .section__footer  {
        .flex__item {
            //display: flex;
        }
        .flex--wrap {
            flex-wrap: nowrap;
        }
    }
}