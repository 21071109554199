.text {
    &--highlight {
        color: $main-color;
    }
    &--inverted {
        color: $inverted;
    }
    &--white {
        color: white;
    }
}

.registered {
    font-size: 0.6em;
}
