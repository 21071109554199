#trainings-section-2 .content.container {
  margin-top: 0;
}

#quote-section {
  .content.container {
    margin-top: 0;
    margin-bottom: 0;
  }
}

#impressum, #datenschutz, #summary, #newsletter-anmeldung, #angemeldet, #blog {
  padding-top: 10vh;
  .column__right {
    &:before {
      display: none;
    }
    .text {
      color: $light;
      a {
        @extend .link;
        @extend .link--inverted;
        &:hover {
          color: $second-color;
        }

      }
      strong {
        color: $second-color;
      }
      &:last-child {
        p {
          @extend .space__bottom__big;
          @include font-size($p-font-sizes-small);
          color: $second-color;
        }
      }
    }

  }
}

#datenschutz p, #impressum p {
  margin-bottom: 1em;
}
