.blog {
  &__entry {
    background: $light;
  }
  &__headline {
    @include x-rem(padding, 15px 30px);
  }
  &__content {
    @include x-rem(padding, 15px 30px);
    ul {
      @extend .space__bottom__small;
      @extend .space__top__small;
      padding-left: 40px;
      li {
        @extend p;
        margin: 0;
        list-style-type: disc;
      }
    }
    ol {
      padding-left: 40px;
      @extend .space__bottom__small;
      @extend .space__top__small;
      li {
        @extend p;
        margin: 0;
        list-style-type: lower-alpha;
      }
    }
    p, strong {
      @extend .space__bottom__medium;
      @extend .space__top__medium;
    }
  }
  &__image {
    img {
      width: 100%;
      height: auto;
    }
  }
  &__date {
    p{
      @include x-rem(padding, 15px 30px);
      @include font-size($p-font-sizes-small);
    }
  }
}