.news_block {
  align-self: flex-start;
  //width: 100%;
  &__item {
    display: flex;
    flex-direction: column;
  }

  &__image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    img {
      width: 100%;
      height: auto;
      visibility: hidden;
    }
  }
  &__image_wrapper {
    @include x-rem(padding, 5px);
    background: $light;
  }
  &__link {
    .button {
      text-align: center;
    }
  }
}

@include mq($until: tablet) {
  .news_block {
    width: 100%;
  }
}

@include mq($from: tablet) {
  .news_block {
    // height: 100%;
    align-self: flex-start;
    max-width: 245px;
    //@include x-rem(margin-right, 20px);
    margin-right: auto;
    &:nth-child(3n+3) {
      margin-right: 0;
    }
    &__text {
      //margin-top: auto;
      @include space(margin, top, small);
    }
    &__item {
      // height: 100%;
    }
    &__image {
      height: 145px;
    }
  }
}