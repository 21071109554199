h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font--sans;
    font-weight: normal;

    &.serif {
        font-family: $font--serif;
        text-transform: none;
    }

}

h1 {
    @include font-size($h1-font-sizes);
    font-weight: bold;
    //line-height: 1;
}

h2 {
    @extend h1;
    @include font-size($h2-font-sizes);
}

h3 {
    @include font-size($h3-font-sizes);
    //line-height: 1.2;
}

h4 {
    @include font-size($h4-font-sizes);
}

h5 {
    @include font-size($h5-font-sizes);
}

h6 {
    @include font-size($h6-font-sizes);
}

p, a, span {
    @include font-size($p-font-sizes);
    font-weight: normal;
}
