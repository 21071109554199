.list {
    &__item {
        position: relative;
        &:before {
            content: url(../images/radio.svg);
            position: absolute;
            top: 6px;
            left: -60px;
        }
    }
}
