.column {
    &__left {
        flex: 1 1 30%;
        display: flex;
        align-items: center;
        @include x-rem(margin-right, 20px);
    }
    &__right {
        flex: 1 1 70%;
        @include x-rem(margin-left, 20px);
        .colorset__reg & {
            &:before {
                display: none;
                content: "";
                @extend .space__bottom__big;
                width: 50%;
                height: 5px;
                background: $light;
            }
        }
    }
    &__full {
        flex: 1 1 100%;

    }
}

.section__headline + .content__wrapper .column__right {
    &:before {
        display: block;
    }
}

.column__full + .column__left,
.column__full + .column__left + .column__right {
    display: none;
}

@include mq($until: tablet) {
    .column {
        margin: 0;
        flex: 1 1 100%;
        &__left {

            order: 2;
        }
        &__right {
            order: 1;
        }
    }
}