.slider {
  padding-top: 110px;
  min-height: 60vh;
  background-size: cover;
  background-position: top center;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  &__title {
    max-width: 100%;
    @include x-rem(padding, 10px 10px)
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;

    background-color: $light;

    h1 {
      background-color: rgba(255, 255, 255, 0.7);
    }

    h1.headline--color-secondary {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  .announcement {
    font-size: 24px;
    color: $light;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: 33px;
    text-align: right;

    margin-bottom: 1rem;
    @include x-rem(padding, 0 10px);
  }

}

@include mq($from: tablet) {
  .slider {
    min-height: 80vh;

    &__title {
      background-color: transparent;
      justify-content: flex-start;
      margin-bottom: 40px;
      padding: 0;

      h1 {
        @include x-rem(padding, 20px 30px)
      }

      .banner {
        @include x-rem(padding-left, 30px)
      }
    }

    /*
    .banner {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(120%);
    }
    */

  }

}
