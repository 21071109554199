
.section__footer {
    padding-top: 80px;
    padding-bottom: 20px;
    background-color: #000;
    font-size: 22px;
}

.newsletter--form {
    margin-top: 20px;
}

.newsletter-form--input {
    width: 60%;
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    float: left;
    border-style: none;
    border-radius: 0px;
    font-size: 22px;
    line-height: 1em;
}

.newsletter-form--submit {
    width: 40%;
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    float: right;
    color: $light;
    border-style: none;
    background-color: #1d3557;
    background-image: url('../images/Triangle.png');
    background-position: 77% 50%;
    background-size: 12px;
    background-repeat: no-repeat;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-size: 22px;
    line-height: 1em;
    text-align: left;
}

.newsletter-form--submit:hover {
    background-color: #e63946;
    background-position: 93% 50%;
}

.footer__container {
    padding-bottom: 50px;
    line-height: 1.7em;
}

.section__footer__h2 {
    margin-bottom: 35px;
    @include x-rem(padding, 0 10px);
}

.section__bottom {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #323333;
    font-size: 16px;
}

.section__footer__links {
    display: inline-block;
    -webkit-transition: border-color 200ms ease;
    transition: border-color 200ms ease;
    color: #fff;
    text-decoration: none;
}

.section__footer__links:hover {
    border-color: #e63946;
}

.icon-link {
    display: inline-block;
    margin-bottom: 30px;
    -webkit-transition: -webkit-transform 200ms cubic-bezier(.645, .045, .355, 1);
    transition: -webkit-transform 200ms cubic-bezier(.645, .045, .355, 1);
    transition: transform 200ms cubic-bezier(.645, .045, .355, 1);
    transition: transform 200ms cubic-bezier(.645, .045, .355, 1), -webkit-transform 200ms cubic-bezier(.645, .045, .355, 1);
}

.icon-link:hover {
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
}

.section__footer__socials {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.newsletter__form__message-container {
    background-color: #1d3557;
}

.newsletter__form__message-container.error {
    background-color: #e63946;
}

.section.section--blured {
    background-color: hsla(0, 0%, 100%, .65);
}

@media (max-width: 991px) {
    .section__footer {
        font-size: 18px;
    }
    .footer__container {
        @include x-rem(padding, 10px);
        // padding-right: 0px;
        // padding-left: 0px;
    }
}

@media (max-width: 767px) {
    .footer__container {
       // padding-right: 5vw;
        //padding-left: 5vw;
        @include x-rem(padding, 10px);
    }
    .icon-link {
        margin-right: 20px;
    }
    .section__footer__socials {
        margin-top: 30px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

@media (max-width: 479px) {
    .section__intro__claim.mobile {
        margin-top: 0px;
    }
    .newsletter-form--input {
        width: 100%;
    }
    .newsletter-form--submit {
        width: 100%;
        float: none;
    }
    .section__bottom {
        //padding-right: 5vw;
        //padding-left: 5vw;
        @include x-rem(padding, 10px);
    }

    .section__footer__socials {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
