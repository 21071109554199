.mobiletoggle {
  &__checkbox {
    display: none;
    &:checked ~ .menu {
      opacity: 1;
      pointer-events: auto;
    }

    &:checked ~ .mobiletoggle__label .mobiletoggle__burger {
      &--top {
        transform: rotate(45deg);
        top: -3px;
        left: 0px;
      }
      &--mid {
        width: 0;
        opacity: 0;
      }
      &--bot {
        top: 25px;
        left: 0px;
        transform: rotate(-45deg);
      }
    }
  }
  &__label {
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
    margin-left: auto;
    transition: 0.5s ease-in-out;
  }
  &__burger {
    position: absolute;
    display: block;
    height: 5px;
    border-radius: 9px;
    width: 100%;
    background: $light;
    .background--white & {
      background: $main-color;
    }
    .color__red & {
      background: $light;
    }
    transform-origin: left center;
    transition: 0.25s ease-in-out;
    &--top {
      top: 0px;
    }
    &--mid {
      top: 10px;
    }
    &--bot {
      top: 20px;
    }
  }
}

@include mq($until: desktop) {
  .mobiletoggle {
    &__checkbox {
      &:checked ~ .menu {
        &:before {
          .background--white & {
            //  background: $light;
          }
        }
      }
    }
  }
}

@include mq($from: desktop) {
  .mobiletoggle__label {
    display: none;
  }
}
