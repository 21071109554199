.headline {
    /* Colors */
    &--highlight {
        color: $main-color;
    }
    &--inverted {
        color: $inverted;
    }
    &--color-main{
        color: $main-color;
    }
    &--color-secondary{
        color: white;
    }
    /* Sizes */
    &--size-big {

    }
    &--regular {
        font-weight: bold;
        letter-spacing: 0.84px;
    }
}

@include mq($until: tablet) {
    .headline {
        &--color-secondary{
            color: $main-color;
        }
    }
}
