.readmore {
    flex: 0 0 100%;

    &__wrapper {

    }
    &__link {
        .colorset1 & {
            color: $light;
        }
        .colorset__light & {
            color: $font-color;
        }
        .colorset__light-red & {
            color: $main-color;
        }
        &:after {
            content: ">";
            @include x-rem(margin-left, 10px);
        }
        .column__right &, .column__full & {

            @extend .link--bordered;
            transition: 200ms ease;
            &:hover {
                border-color: $font-color;
            }
            .colorset__light & {
                border-color: $font-color;
                &:hover {
                    border-color: $light;
                }
            }
            .colorset__light-red & {
                border-color: $main-color;
                &:hover {
                    border-color: $light;
                }
            }

        }
    }

    /* &:after {
         .column__right &, .column__full & {
             display: block;
             content: "";
             @extend .space__top__small;
             width: 50%;
             height: 5px;
             background: $light;
             transition: 200ms ease;
             .colorset__light & {
                 background: $font-color;
             }
             .colorset__light-red & {
                 background: $main-color;
             }

         }
     }*/

    &:hover {
        &:after {
            background: $main-color;
            .colorset__light &, .colorset__light-red & {
                background: $light;
            }
        }
    }
}
