.button {
    background: transparent;
    appearance: none;
    outline: 0;
    border: none;
    @extend .link;
    &__submit {
        color: $light;
        border: 1px solid $main-color;
        @include x-rem(padding, 10px 20px);
    }
    &__main {
        display: inline-block;
        background: $main-color;
        border-radius: 4px;
        @include x-rem(padding, 10px 40px);
        color: $light;
    }
    &__secondary  {
        display: inline-block;
        background: $second-color;
        border-radius: 4px;
        min-width: 100px;
        text-align: center;
        @include x-rem(padding, 5px 15px);
        color: $light;
        @include font-size($p-font-sizes-small);
    }
}

@include mq($until: tablet) {

    .button {
        &__main {
            @include x-rem(padding, 10px 10px);
            width: 100%;
        }
    }
}