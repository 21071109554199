
.content {
    .colorset1 & {
        color: $light;
    }
    .colorset__light & {
        color: $font-color;
    }
    .colorset__light-red & {
        color: $main-color
    }
    &__wrapper {

    }
    &__headline {
        display: inline-block;
        text-transform: uppercase;
        &.border__top {
            &:before {
                content: "";
                height: 5px;
                width: 100%;
                display: block;
                background: $font-color;
                @extend .space__bottom__small;
                .colorset1 & {
                    background: $light;
                }
            }
            @extend .space__bottom__medium;
            @extend .space__top__big;
        }
        &.color--black {
            color: black;
            font-weight: bold;
        }
    }
    &__text {
        &--half {
            @extend .space__top__big;
            max-width: 50%;
        }
        ul {
            @include x-rem(padding-left, 20px);
            li {
                @include font-size($p-font-sizes);
                list-style-type: disc;
            }
        }

    }
    &-image {
        @extend .space__top__big;
        width: 100%;
        text-align: right;
        align-self: flex-start;

        &__image {
          border: 4px solid white;
          border-radius: 4px;
        }
    }
    &__text-bild {
        &__image {
            text-align: center;
            &.image--first {
                flex: 1 1 100%;
                @extend .space__bottom__big;
            }
            &.image--last {
                flex: 1 1 100%;
            }
            &.image--right {
                flex: 1 1 25%;
                order: 2;
            }
            &.image--left {
                flex: 1 1 25%;
                order: 1;
                @include x-rem(margin-right, 20px);
            }

        }
        &__text {
            color: $font-color-light;
            .colorset__light & {
                color: $font-color; 
                p {
                    @include font-size($p-font-sizes-small);
                }
            }
            &.image--first {
                flex: 1 1 100%;
            }
            &.image--last {
                flex: 1 1 100%;
                @extend .space__bottom__big;
            }
            &.image--right {
                flex: 1 1 25%;
                order: 1;
                @include x-rem(margin-right, 20px);
                color: $light;
                .colorset__light & {
                    color: $font-color; 
                }
            }
            &.image--left {
                flex: 1 1 25%;
                order: 2;
                color: $light;
                .colorset__light & {
                    color: $font-color; 
                }
            }
            &.text-big {
                @extend .content__headline;
                margin-top: auto;
                margin-bottom: auto;
            }

        }
    }

}

.text-quote + .content__text {
    // color: $light;
}

@include mq($until: tablet) {
    .content {
        &__text-bild {
            &__image {
                flex: 1 1 100%;
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
                &.image--first, &.image--last, &.image--left, &.image--right {
                    flex: 1 1 100%;
                    max-width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                }
                img {
                    max-width:100%;
                }
            }
            &__text {
                flex: 1 1 100%;
                margin: 0;

            }
        }
        &-image {
            width: 100%;
            text-align: center;
            &__image {
                margin-left: auto;
                margin-right: auto;
            }
        }
        &__text {
            &--half {
             max-width: 100%;
            }
        }
    }
}
