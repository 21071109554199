/* Größen */
$container: 1400px;
$breakpoints: (
        small: 480px,
        medium: 768px,
        large: 960px,
        largeplus: 1200px,
        xlarge: 1600px
);

/* Farben */

$main-color: #D33948;
$second-color: #1D3557;
$light: #fff;
$dark: #000;
$inverted: #9c9c9c;
$footer-color: #343434;
$font-color: $second-color;
$font-color-light: #000;
$font-color-inverted: $light;


$spaces: (
    /*   small, medium, big */
        null: (10px, 12px, 15px),
        small: (10px, 15px, 20px),
        medium: (10px, 15px, 20px),
        large: (10px, 20px, 40px),
        xlarge: (10px, 20px, 40px),
);
$mq-breakpoints: (
        mobile: 480px,
        tablet: 810px,
        desktop: 960px,
        wide: 1200px
);


body:before {
    display: none;
}
