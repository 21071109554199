.page {
    &.background {
        &__variant1 {
            background: linear-gradient($main-color, $second-color);
        }
        &__variant2 {
            background: linear-gradient(#8F374D,$second-color);
        }
        &__variant3 {
            background: linear-gradient(#78374F,$second-color);
        }
        &__variant4 {
            background: linear-gradient(#643651,$second-color);
        }
        &__variant5 {
            background: linear-gradient($main-color,#8F374D);
        }
        &__variant6 {
            background: #c7c7c7;
        }
    }
}