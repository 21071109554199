.references {
    &__image-link {
        opacity: .7;
        // transition: .6s ease;
        &:hover {
            opacity: 1;
        }
    }
}

@include mq($from: tablet) {
    .references {
        &__image-link {
            width: 33%;
            &:nth-child(3n) {
                .references__image-link__link {
                    text-align: right;
                }
            }
            &:nth-child(3n-1) {
                .references__image-link__link {
                    text-align: center;
                }
            }
            &__link {
                //text-align: center;
            }
        }
    }
}

@include mq($until: tablet) {
    .references {
        &__image-link {
            width: 50%;

            &__link {
                text-align: center;
            }
        }

    }
}

@include mq($until: mobileLandscape) {
    .references {
        &__image-link {
            width: 100%;
        }
    }
}
