.pseudo {
    position: relative;
    &--after {
        &:after {
            content: "";
            position: absolute;
        }
    }
    &--before {
        &:before {
            content: "";
            position: absolute;
        }
    }
}