.header {
    @include x-rem(padding, 0 10px);
    transition: .6s ease;
    &--fixed {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;
        &--out {
            top: -150px;
        }
    }
    &__wrapper {
        justify-content: center;
    }
    &.color__red {
        background: $main-color;
    }
}

@include mq($from: desktop) {
    .header {
        @include x-rem(padding, 20px 0);
        &__wrapper {
            justify-content: space-between;
        }
    }

}
