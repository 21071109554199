.newsletter {
    overflow: hidden;
    &__form {
        &__message-container {
            display: none;
        }
    }
    &__title {

    }
    &__text {

    }

}

@include mq($from: tablet) {
    .newsletter {
        width: 66%;
        margin: 0 auto;
    }
}


/* overrides */
.section__footer {
    p {
        line-height: 40px;
    }
}