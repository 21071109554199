// Fonts
//
// -------------------------------------------------------------

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-SemiBold.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-SemiBoldItalic.ttf');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-Bold.ttf');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-BoldItalic.ttf');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-ExtraBold.ttf');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-ExtraBoldItalic.ttf');
  font-style: italic;
  font-weight: 800;
}

$font--sans: Montserrat, sans-serif;
$font--serif: 'Merriweather', "Book Antiqua", Palatino, "Palatino Linotype", "Palatino LT STD", Georgia, serif;
$font--mono: 'Droid Sans Mono', monaco, Consolas, "Lucida Console", monospace;

$body-font: $font--sans;
$body-lineheight: 1.5;
$body-fontweight: normal;

// Headlines
//
// -------------------------------------------------------------
$h1-font-sizes: (
        null: (28px, 1.3),
        small: 30px,
        medium: (36px, 1.4),
        large: (60px, 72px),
        xlarge: (60px, 72px)
);

$h1home-font-sizes: (
        null: (18px, 1.3),
        small: 18px,
        medium: (18px, 1.4),
        large: (18px, 1.45),
        xlarge: 18px,
);

$h2-font-sizes: (
        null: (24px, 1.2),
        small: 24px,
        medium: (28px, 1.3),
        large: (30px, 41px),
        xlarge: (30px, 41px),
);

$h3-font-sizes: (
        null: (20px, 1.2),
        small: 20px,
        medium: (20px, 1.3),
        large: (20px, 1.35),
        xlarge: 20px,
);

$h4-font-sizes: (
        null: (18px, 1.2),
        small: 18px,
        medium: (18px, 1.3),
        large: (18px, 1.35),
        xlarge: 18px,
);

$h5-font-sizes: (
        null: (16px, 1.2),
        small: 16px,
        medium: (16px, 1.3),
        large: (16px, 1.35),
        xlarge: 16px,
);

$h6-font-sizes: (
        null: (14px, 1.2),
        small: 14px,
        medium: (14px, 1.3),
        large: (14px, 1.35),
        xlarge: 14px,
);

// Paragraphs
//
// -------------------------------------------------------------
$p-font-sizes: (
        null: (16px, 32px),
        small: (16px, 32px),
        medium: (20px, 32px),
        large: (20px, 32px),
);
$p-font-sizes-small: (
        null: (16px, 1.5),
        small: (16px, 1.5),
        medium: (16px, 1.8),
        large: (16px, 1.8),
);
$nav-font-sizes: (
        null: (18px, 32px),
        small: (18px, 32px),
        medium: (18px, 32px),
        large: (18px, 32px),
);

$p-lead-font-sizes: (
        null: (15px, 1.5),
        small: 15px,
        medium: (16px, 1.7),
        900px: 16px,
        large: (17px, 1.8),
        1440px: 17px,
);
