@mixin space($direction, $position, $size ,$spacer-map: $spaces, $spacer-breakpoints: $breakpoints) {
    @each $spacer-breakpoint, $spacer-space in $spacer-map {
        @if $spacer-breakpoint == null {
            @include make-space($direction, $position, $size, $spacer-space);
        } @else {
            @if map-has-key($spacer-breakpoints, $spacer-breakpoint) {
                $spacer-breakpoint: map-get($spacer-breakpoints, $spacer-breakpoint);
            }
            @media screen and (min-width: $spacer-breakpoint) {
                @include make-space($direction, $position, $size, $spacer-space);
            }
        }
    }
}

// Utility function for mixin space
@mixin make-space($direction,$positon,$size,$spacer-space) {

    @if type-of($spacer-space) == "list" {

        @if ($size == 'big' and length($spacer-space) > 2) {
            @include x-rem(#{$direction}-#{$positon}, nth($spacer-space, 3));
        } @else if ($size == 'medium' and length($spacer-space) > 1) {
            @include x-rem(#{$direction}-#{$positon}, nth($spacer-space, 2));
        } @else if $size == 'small' {
            @include x-rem(#{$direction}-#{$positon}, nth($spacer-space, 1));
        } @else {
            @include x-rem(#{$direction}-#{$positon}, nth($spacer-space, 1));
        }

    } @else {
        @include x-rem(#{$direction}-#{$positon}, $spacer-space);
    }
}