@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
    @each $fs-breakpoint, $fs-font-size in $fs-map {
        @if $fs-breakpoint == null {
            @include make-font-size($fs-font-size);
        } @else {
            // If $fs-font-size is a key that exists in
            // $fs-breakpoints, use the value
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }
            @media screen and (min-width: $fs-breakpoint) {
                @include make-font-size($fs-font-size);
            }
        }
    }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
    // If $fs-font-size is a list, include
    // both font-size and line-height
    @if type-of($fs-font-size) == "list" {
        //@include x-rem(font-size, nth($fs-font-size, 1));
        font-size: nth($fs-font-size, 1);
        @if (length($fs-font-size) > 1) {
            line-height: nth($fs-font-size, 2);
        }
        @if (length($fs-font-size) > 2) {
            margin-bottom: nth($fs-font-size, 3) * 1rem;
        }
    } @else {
        //@include x-rem(font-size, $fs-font-size);
        font-size: $fs-font-size;
    }
}
