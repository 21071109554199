.background {
    transition: .6s ease;
    &--light {
        background: rgba($light,.7);
    }
    &--white {
        background: $light;
    }
    &--lighter {
        background: rgba($light,.85);
    }
    &--medium {
        background: $footer-color;
    }
    &--dark {
        background: $dark;
    }
    &--highlight {
        background: $main-color;
        color: white;
    }
    &__image {
        background-repeat: no-repeat;
        &--full {
            background-size: cover;
            background-position: center;
        }
        &--contain {
            background-size: contain;
        }
        &--inline {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &--gardient {
        background: linear-gradient($main-color, $second-color)
    }
}