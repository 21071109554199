.page_team {
  @include x-rem(padding, 80px 0);
  h1 {
    display: inline-block;
    @include x-rem(padding, 20px);
    background: rgba(255, 255, 255, 0.4);
    color: $light;
  }
  h2 {
    background: none;
    @include x-rem(margin, 40px 0 20px 0);
  }
  a {
    text-decoration: none;
    color: inherit;
  }
}
.page_team_detail {
  .left {
    display: flex;
    flex: 1 1 40%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h2 {
      max-width: 100%;
    }
    img {
      border-radius: 100%;
      max-width: 50%;
    }
  }
  .right {
    flex: 1 1 60%;
  }
}
.team {
  @include x-rem(margin, 40px 0);
  justify-content: center;
  .team__member {
    position: relative;
    width: 100%;
    @include x-rem(margin-left, 20px);
    @include x-rem(margin-right, 20px);
    &__image {
      text-align: center;
      img {
        border-radius: 100%;
        overflow: hidden;
        object-fit: cover;
        object-position: center;
        width: 14rem;
        height: 14rem;
      }
    }
    &__title h4 {
      letter-spacing: 1px;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
    }
    &__description {
      text-align: center;
    }
    &:after {
      content: "";
      border: 3px solid $light;
      width: 40%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
@include mq($from: tablet) {
  .team {
    .team__member {
      max-width: calc(47% - 40px);
    }
  }
}
@include mq($from: desktop) {
  .page_team {
    h2 {
      @include x-rem(margin, 40px 0 20px 80px);
      max-width: 50%;
    }
  }
  .team {
    .team__member {
      max-width: calc(30% - 40px);
    }
  }
}
