.flex {
    display: flex;
    > * {
        order: 1;
        flex: 1 1 auto;
    }
    &--center {
        align-items: center;
        justify-content: space-around;
        &--center {
            justify-content: center;
        }
    }
    &--start {
        align-items: center;
        justify-content: flex-start;
    }
    &--spread {
        justify-content: space-between;
    }
    &--end {
        align-items: center;
        justify-content: flex-end;
    }
    &--wrap {
        flex-wrap: wrap;
    }
    &--wrap-mobile {
        @include mq($until: tablet) {
            flex-wrap: wrap;
        }
    }
    &--column {
        flex-direction: column;
    }
    &__item {

        &__3 {
            &--1 {
                flex: 1 1 33%;
            }
            &--2 {
                flex: 1 1 66%;
            }
            &--3 {
                flex: 1 1 100%;
            }
        }
        &__4 {
            &--1 {
                flex: 1 1 25%;
            }
            &--2 {
                flex: 2 1 50%;
            }
            &--3 {
                flex: 3 1 75%;
            }
            &--4 {
                flex: 4 1 100%;
            }
        }
    }
}