.space {
    &__top {
        &__small {
            @include space('margin', 'top', 'small');
        }
        &__medium {
            @include space('margin', 'top', 'medium');
        }
        &__big {
            @include space('margin', 'top', 'big');
        }
    }
    &__bottom {
        &__small {
            @include space('margin', 'bottom', 'small');
        }
        &__medium {
            @include space('margin', 'bottom', 'medium');
        }
        &__big {
            @include space('margin', 'bottom', 'big');
        }
    }

    &__inset {
        &__top {
            &__small {
                @include space('padding', 'top', 'small');
            }
            &__medium {
                @include space('padding', 'top', 'medium');
            }
            &__big {
                @include space('padding', 'top', 'big');
            }
        }
        &__bottom {
            &__small {
                @include space('padding', 'bottom', 'small');
            }
            &__medium {
                @include space('padding', 'bottom', 'medium');
            }
            &__big {
                @include space('padding', 'bottom', 'big');
            }
        }
    }

}